import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Router} from '@reach/router';
import Layout from '../components/Layout/Layout';

import {doubleOptIn} from '../state/actions/userManagement';

import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import {PageTemplateStyled} from '../templates/styles';
import Headline from '../components/Elements/Headline/Headline';
import Text from '../components/Elements/Text/Text';
import Button from '../components/Elements/Button/Button';

const Token = (props) => {
	const {token, handleVerify} = props;

	const verifyUser = (data) => {
		handleVerify(data);
	};

	verifyUser(token);

	return null;
};

const VerifyUser = (props) => {
	const {location} = props;

	return (
		<>
			<Meta title={appendSeoTitleSuffix('Setze dein neues Passwort')} />
			<Layout location={location}>
				<PageTemplateStyled>
					<Section center showPadding>
						<Headline as="h1" center text="Vielen Dank für die Bestätigung!" />
						<Text
							content="Du kannst dich ab jetzt mit deinem Benutzernamen und
							Passwort anmelden."
							center
							maxWidth={850}
						/>
						<Button to="/login" type="link" text="Zur Anmeldung" />
						<Router>
							<TokenCon path="/verify-user/:token" />
						</Router>
					</Section>
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	handleVerify: (data) => dispatch(doubleOptIn(data)),
});

const TokenCon = connect(null, mapDispatchToProps)(Token);

VerifyUser.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
};

Token.propTypes = {
	token: PropTypes.string,
};

Token.defaultProps = {
	token: undefined,
};

export default VerifyUser;
